import { PropType, defineComponent, inject, ref } from "vue";
import CustomSelect, { selectDivider, OptGroup as CustomOptGroup, Options as CustomOptions, isOptGroup } from "cdes-vue/util/form/CustomSelect.vue";
import Label from "cdes-vue/util/form/Label.vue";
import { FormMode } from "cdes-vue/voc/gui/FormMode";
import FormGridMember from "cdes-vue/util/form/FormGridMember.vue";
import { formModeKey } from "cdes-vue/util/form/FormGrid.vue";
import { FormHelper } from "./FormHelper";

export class SelectOption {
    label : string;
    value : string | number;
    children? : SelectOption[]

    constructor(value : string | number, label : string) {
        this.value = value;
        this.label = label;
    }
}

export { selectDivider };
export type OptGroup = CustomOptGroup<SelectOption>;
export type Options = CustomOptions<SelectOption>;

function defaultCmp(a_: SelectOption, b_: SelectOption): number {
    const a = a_.label.trim();
    const b = b_.label.trim();
    if (a < b) {
        return -1;
    } else if (a > b) {
        return 1;
    } else {
        return 0;
    }
}

export default defineComponent({
    components: {
        CustomSelect,
        Label,
        FormGridMember
    },

    props : {
        required : {
            type : Boolean
        },
        
        mode : {
            type : String as PropType<FormMode>,
        },

        disabled : {
            type : Boolean
        },

        wasValidated : {
            type : Boolean
        },

        invalidFeedback : {
            type : String
        },

        id : {
            type : String
        },

        label : {
            type : String
        },

        labelClass : {
            type : String
        },

        modelValue : {
            type : [Number, String],
            required: false,
        },

        options : {
            type : Array as PropType<Options>
        },

        selectClass : {
            type : String
        },

        title : {
            type : String
        },

        widthMode : {
            type : String
        },
        optional: Boolean,

        defaultLabel : {
            type : String,
            default : ""
        },

        sort: {
            type: [Boolean, Function] as PropType<boolean | ((a: SelectOption, b: SelectOption) => number)>,
        },
    },

    data() {
        return {
            injectedFormMode : ref(inject(formModeKey, undefined))
        }
    },

    emits: ['update:modelValue'],

    computed : {
        effectiveMode() : FormMode {
            if (this.mode != null) {
                return this.mode;
            } else {
                return this.injectedFormMode;
            }
        },
        
        isFlexMode() : boolean {
            return this.effectiveMode == FormMode.FLEX;
        },

        isFormGridMode() : boolean {
            return this.effectiveMode == FormMode.FORM_GRID;
        },        

        computedLabelCss() : string {
            let cssClasses = "text-end selectLabel ";
            if (this.labelClass != null) {
                cssClasses += this.labelClass;
            }
            return cssClasses;
        },

        computedSelectCss() : string {
            let cssClasses = "";
            if (this.selectClass != null) {
                cssClasses += this.selectClass;
            }
            if (this.widthMode == "auto") {
                cssClasses += " autoWidth";
            }
            return cssClasses;
        },

        selectId(): string {
            return this.id != null ? this.id : this.$id('select');
        },

        trueOptions(): SelectOption[] {
            if (this.sort == false) {
                return this.options as SelectOption[];
            }

            if (this.options.some(isOptGroup)) {
                throw new Error("Attempted to sort select with option groups");
            }

            const cmp = (typeof this.sort == "boolean") ? defaultCmp : this.sort;

            return (this.options as SelectOption[]).sort(cmp as any);
        }
    },

    methods : {
/*
        isFlexMode() : boolean {
            return this.mode == FormMode.FLEX;
        },

        isFormGridMode() : boolean {
            return this.mode == FormMode.FORM_GRID;
        },
*/
        valueString(value: unknown): string {
            if (value == null) {
                return "";
            } else {
                return "" + value;
            }
        },

        changed(evt : Event) : void {
            let value : string | number = null;
            let searchedValue : string = (evt.target as HTMLSelectElement).value;
            for (let option of (this.options as SelectOption[])) {
                if (this.valueString(option.value) === searchedValue) {
                    value = option.value;
                    break;
                }
            }

            this.$emit("update:modelValue", value);
        }
    }
});
